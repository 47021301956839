import { logger } from '@belong/logging';
import { Entry } from '@belong/types';
import { FAdobeTargetActivity } from './factories';
import {
  IAdobeTargetExperience,
  IAdobeTargetResponse,
  IFindAdobeTargetOffer,
  IGetExperienceSections,
  IMutateSectionsWithAdobeTargetOffer
} from './types';
import { selfTerminate } from './selfTermination';
import { getOffer } from './getExperience';
import { CONFIG } from '../target-config';

// grab the mbox names from the activities and get the offer from Adobe Target
// we're only dealing with one offer at this time although can be used to make multiple changes on the page
export const findAdobeTargetOffer = async ({
  ctx,
  sessionId,
  supplementalDataId,
  unparsedPageEntry,
  isAuthenticatedCustomer,
  forceControlExperience
}: IFindAdobeTargetOffer): Promise<IAdobeTargetResponse> => {
  const unparsedSections = unparsedPageEntry?.fields.sections || [];
  const terminationTimer = CONFIG.timeout || 200;

  // target sections will be an array of sections that have an mboxName
  const targetSections = findTargetSections(unparsedSections);

  if (targetSections.length) {
    try {
      // check that there is an actual target
      const reqUrl = ctx?.req?.headers.referer;
      const url = reqUrl ? new URL(reqUrl) : undefined;

      // Ensure ctxProtocol is either "http" or "https"
      let ctxProtocol: 'http' | 'https' = 'http'; // Default to 'http'
      if (url?.protocol) {
        ctxProtocol = url.protocol === 'https:' ? 'https' : 'http';
      }

      const offer = await selfTerminate(
        getOffer({
          ctxProps: {
            ctxProtocol,
            ctxHost: ctx?.req?.headers.host || undefined,
            ctxPath: ctx.asPath,
            ctxHeaders: ctx?.req?.headers || {},
            ctxCookies: ctx?.req?.headers.cookie || undefined,
            ctxQuery: ctx.query
          },
          mboxLocation: targetSections[0].fields.mBoxName,
          sessionId,
          supplementalDataId,
          isAuthenticatedCustomer,
          forceControlExperience // this lets Adobe target know that the user accessing the page is a control/excluded group
        }),
        terminationTimer
      );
      return offer || null;
    } catch (e: any) {
      logger.error('Error getting the Adobe Target offer', e);
      return null;
    }
  }

  // return undefined if there are no target sections
  return undefined;
};

// return the experience section from the activity that matches the returned value from Adobe
export const getExperienceSections = ({
  adobeTargetActivity,
  offer,
  forceControlExperience = false
}: IGetExperienceSections): Entry<any>[] => {
  // if the offer hasn't been found return the default experience
  if (offer == null || forceControlExperience) {
    return adobeTargetActivity.experiences[0].experienceContent;
  }

  const { mboxes } = offer.execute;
  const { options } = mboxes[0];

  // if there are no options then return the default experience
  // this is a fallback in case the Adobe Target activity is not active
  if (!options) {
    return adobeTargetActivity.experiences[0].experienceContent;
  }

  // find the experience in the activity that matches the returned value from Adobe
  const experienceSections =
    adobeTargetActivity.experiences.find(
      (experience: IAdobeTargetExperience) => experience.experienceName === options[0].content.experienceName
    )?.experienceContent || adobeTargetActivity.experiences[0].experienceContent; // additional default fallback protection
  return experienceSections;
};

export const mutateSectionsWithAdobeTargetOffer = ({
  offer,
  unparsedPageEntry,
  forceControlExperience = false
}: IMutateSectionsWithAdobeTargetOffer): Entry<any> => {
  const unparsedSections = unparsedPageEntry?.fields.sections || [];
  const targetSections = findTargetSections(unparsedSections);

  // filter the unparsedSections for any targetSections that don't have an mboxName. If they get through things will break
  const sanitisedUnparsedSections = unparsedSections.filter(
    (section: Entry<any>) =>
      section.sys.contentType.sys.id !== 'adobeTargetActivity' ||
      (section.sys.contentType.sys.id === 'adobeTargetActivity' && section.fields.mBoxName !== undefined)
  );

  const factoriedTargetSections = targetSections.map((section: Entry<any>) => FAdobeTargetActivity(section));

  if (targetSections) {
    let targetIndex = 0; // track target and offer to use when we find a target section
    const parsedSections = sanitisedUnparsedSections.flatMap((section: Entry<any>) => {
      // flatMap here ensures that multiple sections are returned to parsedSections without being wrapped in an array
      if (section.sys.contentType.sys.id === 'adobeTargetActivity') {
        // match the mboxName from the section with the mboxName from the offer
        const experienceSections = getExperienceSections({
          adobeTargetActivity: factoriedTargetSections[targetIndex],
          offer,
          forceControlExperience
        });
        targetIndex += 1;

        return experienceSections;
      }

      return section;
    });

    // return the page entry with the updated sections... or not
    return {
      ...(unparsedPageEntry as Entry<any>),
      fields: {
        ...(unparsedPageEntry?.fields ?? {}),
        sections: parsedSections
      }
    };
  }
  return unparsedPageEntry as Entry<any>;
};

export const findTargetSections = (unparsedSections: Entry<any>[]): Entry<any>[] => {
  return unparsedSections.filter(
    (section: Entry<any>) =>
      section.sys.contentType.sys.id === 'adobeTargetActivity' && section.fields.mBoxName !== undefined
  );
};
