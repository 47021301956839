import { useEffect, useMemo } from 'react';
import Head from 'next/head';
import type { NextPage, NextPageContext } from 'next';
import { Cookies } from 'react-cookie';

import { getConfig } from '@belong/configs/next/config';
import { checkForPromo } from '@belong/nbn-core/utils';
import { getCorrelationId, platformConfig } from '@belong/utils';
import { getUrlPath, withBasePath, withoutBasePath } from '@belong/utils/url';
import { IPage, Entry, IContentfulSettings, PLATFORM_TYPE, USER_TYPE } from '@belong/types';
import useAnalyticsEvents from '@belong/providers/analyticsEvents';
import AnalyticsContext from '@belong/providers/analyticsEvents/Context';
import useConnectToAnalyticsVendors from '@belong/react-hooks/useConnectToAnalyticsVendors';
import { useAnalyticsDebugger } from '@belong/providers/analyticsEvents/debug';
import { pageView } from '@belong/analytics';
import {
  getContentfulSettings,
  getEntriesByTag,
  getPageContent,
  FPage,
  findEntryForPromotion
} from '@belong/contentful';
import { logger } from '@belong/logging/logger';
import { IncomingMessage } from 'http';
import { checkAuthenticationCookies } from '@belong/providers/authentication';

import {
  findAdobeTargetOffer,
  mutateSectionsWithAdobeTargetOffer,
  getSupplementalDataId,
  IAdobeTargetOffer
} from '@belong/adobe-target';
import { useRouter } from 'next/router';
import { COOKIES, PUBLIC_PAGES } from '@belong/constants/src';
import { getCookie } from '@belong/cookies';
import { getParsedSection } from '../helpers/contentful';
import FArticleBlog, { doesPageLinkToBlogArticle, isIArticleBlog } from '../contentful/factories/FArticleBlog';
import ArticleBlogView from '../components/templates/ArticleBlogView';
import PageUnauthenticatedView from '../components/templates/PageUnauthenticatedView';
import ErrorPage from './_error';

export interface IDynamicPageProps {
  pageData: IPage;
  statusCode?: number;
  relatedArticles?: Entry<any>[];
  adobeTargetOffer?: IAdobeTargetOffer | void;
}

// Extend the IncomingMessage type to include cookies
interface IIncomingMessageWithCookies extends IncomingMessage {
  cookies: { [key: string]: string };
}
interface ICustomNextPageContext extends NextPageContext {
  req: IIncomingMessageWithCookies;
}

const DynamicPage: NextPage<IDynamicPageProps> = ({ pageData, relatedArticles, adobeTargetOffer, statusCode }) => {
  const analyticsEvents = useAnalyticsEvents();
  const config = useMemo(() => getConfig().publicRuntimeConfig, []);
  useConnectToAnalyticsVendors(analyticsEvents, config.analytic.shouldRun);
  useAnalyticsDebugger();

  const { query } = useRouter();
  const slug = query.slug as string[];
  const isSearchResultsPage = slug && slug[0] === withoutBasePath(PUBLIC_PAGES.SEARCH).replace(/\//g, '');
  const searchQuery = (isSearchResultsPage && (query.query as string)) || undefined;

  const {
    pageTitle,
    description,
    keywords,
    sections: pageSections,
    pageUrl,
    journeyName,
    noIndex,
    noFollow
  } = pageData;

  useEffect(() => {
    analyticsEvents.send(pageView(pageData, config));
  }, [pageTitle, pageUrl, analyticsEvents]);

  if (statusCode && statusCode >= 400) {
    return <ErrorPage statusCode={statusCode} />;
  }

  if (!pageData) {
    return <ErrorPage statusCode={500} />;
  }

  const sections: { contentType?: string; [key: string]: any }[] = pageSections ?? [];

  return (
    <>
      <AnalyticsContext
        value={{
          currentUrl: pageUrl,
          journeyName,
          ...(adobeTargetOffer?.status === 200 && { experiments: adobeTargetOffer })
        }}
      />
      <Head>
        <title key="title">{`${searchQuery ? `"${searchQuery}" - ` : ''}${pageTitle}`}</title>
        <meta name="description" key="description" content={description} />
        <meta name="format-detection" content="telephone=no" />
        {keywords && keywords !== '' && <meta name="keywords" key="keywords" content={keywords} />}
        {noIndex && noFollow && <meta name="robots" content="noindex, nofollow" />}
        {noIndex && !noFollow && <meta name="robots" content="noindex" />}
        {!noIndex && noFollow && <meta name="robots" content="nofollow" />}
      </Head>
      {isIArticleBlog(pageData) ? (
        <ArticleBlogView page={pageData} relatedArticles={relatedArticles ?? []} />
      ) : (
        <PageUnauthenticatedView sections={sections} />
      )}
    </>
  );
};

DynamicPage.getInitialProps = async (ctx: ICustomNextPageContext): Promise<IDynamicPageProps> => {
  const correlationId = getCorrelationId(ctx);
  const { asPath, req, res } = ctx;
  logger.debug(correlationId, `Served by public: ${ctx.asPath}`);

  const isNativeApp = platformConfig.platform !== PLATFORM_TYPE.BROWSER;
  const { isLoggedIn, userType } = checkAuthenticationCookies(req?.cookies || new Cookies(), isNativeApp);

  const contentfulSettings: IContentfulSettings = getContentfulSettings(
    new Cookies(req?.headers.cookie),
    getConfig().publicRuntimeConfig.contentful
  );
  const { items, statusCode } = await getPageContent({
    contentfulSettings,
    pagePath: withBasePath(getUrlPath(asPath)),
    correlationId
  });

  if (statusCode) {
    if (res) {
      res.statusCode = statusCode;
    }

    return { statusCode, pageData: {} as IPage };
  }

  const pageEntry = findEntryForPromotion(items, checkForPromo(ctx));

  /*
    Supplemental Data ID is sent to Adobe Target when requesting an experience,
    and then the same ID is sent to the Page Analytics call. This lets Adobe
    stitch the Target Experience together with the Analytics data so we can
    do reporting.
  */
  const supplementalDataId = getSupplementalDataId();

  const forceControlExperience = getCookie(COOKIES.NDC, ctx) === 'Yes'; // force control experience for NDC

  // this is where we parse the pageEntry to check for Adobe Target activities
  const adobeTargetOffer = await findAdobeTargetOffer({
    ctx,
    sessionId: correlationId,
    supplementalDataId,
    unparsedPageEntry: pageEntry,
    isAuthenticatedCustomer: isLoggedIn && userType === USER_TYPE.CUSTOMER,
    forceControlExperience
  });

  let parsedPageEntry = pageEntry;
  // there may not be an Adobe Target offer or retrieving the offer may fail

  if (adobeTargetOffer || adobeTargetOffer === null) {
    // inject the results of the Adobe Target offers into the page entry
    parsedPageEntry = mutateSectionsWithAdobeTargetOffer({
      offer: adobeTargetOffer,
      unparsedPageEntry: pageEntry,
      forceControlExperience
    });
  }

  const pageData = await FPage([parsedPageEntry], getParsedSection);
  let relatedArticles = [];

  if (doesPageLinkToBlogArticle([parsedPageEntry] as Entry<any>[])) {
    const articleData: Entry<any> = parsedPageEntry?.fields.page ?? parsedPageEntry?.fields.blogArticle;

    pageData.blogArticle = await FArticleBlog(articleData, getParsedSection);
    relatedArticles = await getRelatedArticles([parsedPageEntry], contentfulSettings);
  }

  return {
    pageData,
    relatedArticles,
    adobeTargetOffer: { ...adobeTargetOffer, supplementalDataId } as IAdobeTargetOffer
  };
};

const PAGE_CONTENT_TYPE = 'page';
const CATEGORY_TAG_PREFIX = 'category'.toLowerCase();

/**
 * Find related pages by tags (for blog articles)
 */
const getRelatedArticles = async (pageProps: any, contentfulSettings: IContentfulSettings): Promise<any> => {
  try {
    // @ts-ignore
    const { tags } = pageProps[0].metadata;
    // NOTE. the tag sys.id is the tag ID, not the tag name
    const categoryTags = tags.map(t => t.sys.id).filter(t => t.toLowerCase().startsWith(CATEGORY_TAG_PREFIX));

    if (categoryTags.length) {
      return (
        await getEntriesByTag({
          contentfulSettings,
          tags: categoryTags,
          contentType: PAGE_CONTENT_TYPE
        })
      ).items;
    }
  } catch (e) {
    logger.error(e);
  }
};

export default DynamicPage;
